* {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

a:hover {
  color: inherit;
  text-decoration: inherit;
}

@media (min-width: 992px){
  .width-850 {
      max-width: 850px !important;
  }
}

@media (max-width: 992px){
  .modal-90w {
      max-width: 90% !important;
  }
}

@media (max-width: 576px){
  .modal-90w {
      max-width: 100% !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');